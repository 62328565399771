import {
  Card,
  CardWrapper,
  FileUpload,
  FormFirstFocus,
  Icon,
  useRequestInit,
  PlatformFoutenSamenvatting
} from "adviesbox-shared";
import { Form, FormikProps, useFormikContext } from "formik";
import React, { ReactElement, useContext, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import AutorisatieNiveauContext from "../autorisatie-niveau-context/autorisatie-niveau-context";
import { InstellingNiveauOptions } from "../.generated/instellingen-forms/instellingen-formstypes";
import { SaveButton } from "../save-button/save-button";
import { Debug } from "../shared/components/formik/Debug";
import { WithSaveData } from "../shared/utils/save-data";
import withAdviesboxFormik from "../shared/utils/with-adviesbox-formik";
import { deleteVoorbladApi, getBlobUrlApi, uploadFotoVanVoorbladApi } from "./documenten-afbeeldingvoorblad-api";
import classes from "./documenten.module.scss";
import { DocumentenProps, documentenSchema, DocumentenState } from "./infra/documenten-schema";
import { InstelingenOverzichten } from "./instelingen-overzichten/instelingen-overzichten";
import { InstelingenVoorbladEnVervolgpaginas } from "./instelingen-voorblad-en-vervolgpaginas/instelingen-voorblad-en-vervolgpaginas";
import { KolommenInDeLastenoverzichten } from "./kolommen-in-de-lastenoverzichten/kolommen-in-de-lastenoverzichten";
import { KolommenInHetNettoBesteedbaarInkomenOverzicht } from "./kolommen-in-het-netto-besteedbaar-inkomen-overzicht/kolommen-in-het-netto-besteedbaar-inkomen-overzicht";
import Ondertekening from "./ondertekening/ondertekening";

const DocumentenComponent = (props: FormikProps<DocumentenState> & DocumentenProps): ReactElement => {
  const filesRef = useRef<File[]>([]);
  const { isBeheerder } = useContext(AutorisatieNiveauContext);
  const { autorisatieNiveau } = useContext(AutorisatieNiveauContext);
  const { settings, params, user } = useRequestInit<{ vestiging: string }>();
  const formik = useFormikContext<DocumentenState>();
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [voorblad, setVoorblad] = React.useState<string | null>(null);

  useEffect(() => {
    /* eslint-disable-next-line @typescript-eslint/no-floating-promises */
    getBlobUrlApi(settings, user, params.vestiging, setVoorblad);
  }, [settings, user, params.vestiging, props.setFieldValue]);

  return (
    <FormFirstFocus>
      <Form>
        <PlatformFoutenSamenvatting />

        <fieldset disabled={!(isBeheerder || autorisatieNiveau.documentNiveau === InstellingNiveauOptions.Medewerker)}>
          <CardWrapper className="px-3">
            <div className="text-container">
              <h2>Documenten</h2>
              <div className="save-btn-position">
                <SaveButton context={props} />
              </div>
            </div>
          </CardWrapper>

          <div className={classes.documenten_wrapper}>
            <CardWrapper>
              <Card title="Ondertekening">
                <Ondertekening />
              </Card>
              <Card title="Instelingen overzichten">
                <InstelingenOverzichten />
              </Card>
              <Card title="Instelingen voorblad en vervolgpagina's">
                <InstelingenVoorbladEnVervolgpaginas />
              </Card>
              {/* todo:#66385 disabled until platform functionality is completed */}
              <Card title="Afbeelding voorblad">
                <div className="p-3">
                  <p>
                    Hier kan de afbeelding voor het voorblad toegevoegd worden. Als er geen afbeelding toegevoegd is dan
                    zal het voorblad van het document geen plaatje bevatten. Let op; als er in het advies gekozen is
                    voor een afbeelding van de woning op de voorkant zal deze de afbeelding van het voorblad
                    overschrijven.
                  </p>

                  {/*istanbul ignore next*/ voorblad && (
                    <div>
                      <img
                        ref={imgRef}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.style.display = "none";
                          setVoorblad(null);
                        }}
                        data-testid="blob-image-voorblad"
                        src={voorblad}
                        className={classes.voorblad}
                        alt="Aan te kopen woning"
                      />
                      {imgRef.current?.style.display !== "none" && (
                        <Button
                          data-testid="btn-verwijder-woning-foto"
                          id="btn-verwijder-woning-foto"
                          variant="outline-secondary"
                          onClick={async (event: React.MouseEvent<HTMLElement, MouseEvent>): Promise<void> => {
                            setVoorblad(null);
                            await deleteVoorbladApi(settings, user, params.vestiging);
                            event.stopPropagation();
                          }}
                        >
                          <Icon name="prullenbak" alt="Verwijderen" />
                        </Button>
                      )}
                    </div>
                  )}

                  {/*istanbul ignore next*/ !voorblad && (
                    <FileUpload
                      name={"voorblad"}
                      title={
                        <span className={"font-weight-bold"}>
                          Sleep de afbeelding voor het voorblad in dit vak
                          <br /> of
                          <br />
                        </span>
                      }
                      variant={"Afbeeldingen"}
                      multipleFiles={false}
                      updateRef={
                        /*istanbul ignore next*/ async (f: File[]) => {
                          const file = f[0];
                          const formData = new FormData();
                          formData.append("voorbladAfbeeldingBestand", file ?? new Blob());
                          const uploadSuccess = await uploadFotoVanVoorbladApi(
                            settings,
                            user,
                            params.vestiging,
                            formData,
                            formik
                          );

                          if (uploadSuccess) {
                            const locatieFoto = await getBlobUrlApi(settings, user, params.vestiging);
                            if (locatieFoto) {
                              if (imgRef.current) imgRef.current.style.display = "inherit";
                              setVoorblad(locatieFoto);
                            }
                          }
                        }
                      }
                      filesref={filesRef}
                    ></FileUpload>
                  )}
                </div>
              </Card>
            </CardWrapper>
          </div>

          <div className={classes.documenten_wrapper}>
            <CardWrapper>
              <Card title="Kolommen in de lastenoverzichten">
                <KolommenInDeLastenoverzichten />
              </Card>
              <Card title="Kolommen in het netto besteedbaar inkomen overzicht">
                <KolommenInHetNettoBesteedbaarInkomenOverzicht />
              </Card>
            </CardWrapper>
          </div>
        </fieldset>
        <Debug />
      </Form>
    </FormFirstFocus>
  );
};

export const Documenten = withAdviesboxFormik<DocumentenProps & WithSaveData<DocumentenState>, DocumentenState>({
  // Transform outer props into form values
  mapPropsToValues: (e: DocumentenProps): DocumentenState => e,
  validationSchema: documentenSchema
})(DocumentenComponent);
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") Documenten.displayName = "Documenten";
